import React from 'react'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Tips } from '../components/estilos/estilos-de-articulo'
import EspaciadorVertical from '../components/espaciador-vertical'
import Navegar from '../components/navegar'

import { graphql } from 'gatsby'

// #region GraphQL
export const query = graphql`
  {
    allTipsConvalidareYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const TipsConvalidare = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allTipsConvalidareYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Tips>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda fluid={toFluid('imgblogtconvalidareconvalida3jpg')} />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>Timbrado de boletos de trenes</h2>
                <p>
                  ¿Cuándo y dónde timbrar los boletos de los trenes? Te presento las famosas
                  maquinitas para «convalidare» los boletos de los trenes. Uso la palabra en
                  italiano porque es como la vas a ver escrita en los boletos y en las máquinas.
                </p>
                <p>
                  Las dos primeras fotos corresponden a la estación de trenes del aeropuerto de
                  Fiumicino, localidad cercana a Roma (como nuestra Ezeiza). En realidad el
                  aeropuerto se llama Leonardo da Vinci. Tendrías que usar esta maquinita si fueras
                  a tomar el tren «Regionale veloce» que va a la estación Roma Tiburtina. Pero lo
                  más probable es que te dirijas a Roma Termini y solo uses la lectora de código QR.
                  Tiene a mano el ticket porque puede ser que pase el guarda y te lo pida.
                </p>
                <h3>¿Cuándo debes timbrar el boleto?/</h3>
                <p>
                  Cuando vayas de Termini al aeropuerto o vayas a tomar cualquiera tren cuyo boleto
                  no tenga fija la fecha, la hora y probablemente tampoco designado el asiento,
                  tendrás que timbrarlo. Las maquinitas se encuentran adosadas a las columnas. Te
                  muestro en la tercera foto una en Termini.
                </p>
                <p>
                  Ojo, si tuvieras que cambiar de andén tendrías que timbrar el boleto antes porque
                  te puede pasar como me sucedió más de una vez, que llegues corriendo con la lengua
                  afuera (¿por qué será que yo siempre estoy corriendo trenes?) y a punto de perder
                  el tren y busques la famosa maquinita y descubras que no la hay y que eso
                  signifique que debas bajar una escalera, correr por un largo pasillo, subir otra
                  escalera, buscar la maquinita y volver corriendo a hacer el trayecto inverso,
                  tratando de no rodar y ¡todo con la valija a cuestas!
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h3>Solución de emergencia</h3>
                <p>
                  Te paso la posta. Si tu caso es que el tren en cuestión ya está en el andén, no
                  tienes tiempo para buscar la maquinita entonces, subes al primer vagón, que es
                  donde está el guarda y le das el boleto para que te lo timbre. Si no haces así,
                  cuando te lo pida y lo vea sin timbrar te hará pagar una multa que puede llegar a
                  €200!!!
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda fluid={toFluid('imgblogtconvalidareconvalida2jpg')} />
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Tips>
    </Marco>
  )
}
export default TipsConvalidare
